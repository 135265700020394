export const notNull = <TValue>(
    value: TValue,
): value is NonNullable<TValue> => {
  return value !== null && value !== undefined;
};
export const optionalize = <
    T extends (...args: any[]) => any,
    R extends ReturnType<T> | undefined,
>(
    func: T,
    defaultValue?: R,
) => {
  return (
      ...args: Partial<Parameters<T>>
  ): R extends undefined ? ReturnType<T> | undefined : ReturnType<T> => {
    return args != null && args[0] != null ? func(...args) : defaultValue;
  };
};

export const instantiate = <T extends object>(
    constructor: new (data: any) => T,
    args: any,
): T => {
  return new constructor(args);
};

export const tryParse = (data: any) => {
  if (data == null) return data;
  return JSON.parse(data);
};
